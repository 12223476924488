<template>
  <div class="card shadow-lg">
    <img
      src="../../../assets/img/shapes/pattern-left.png"
      alt="pattern-lines"
      class="position-absolute overflow-hidden opacity-4 start-0 top-0 h-100 border-radius-xl"
    />
    <img
      src="../../../assets/img/shapes/pattern-right.png"
      alt="pattern-lines"
      class="position-absolute overflow-hidden opacity-4 end-0 top-0 h-100 border-radius-xl"
    />
    <div class="card-body px-5 z-index-1 bg-cover">
      <div class="row">
        <div class="col-lg-3 col-12 my-auto">
          <h4 class="text-body opacity-9 text-center">Wedstrijd scores</h4>
          <hr class="horizontal light mt-1 mb-3" />
          <div class="d-flex justify-content-center">
            <div>
              <h6 class="mb-0 text-body opacity-7">Running Distance</h6>
              <h3 class="text-body">
                123
                <small class="text-sm align-top">Km</small>
              </h3>
            </div>
            <div class="ms-lg-6 ms-4 text-center">
              <h6 class="mb-0 text-body opacity-7">Average Defensive Score</h6>
              <h3 class="text-body">
                21
                <small class="text-sm align-top">%</small>
              </h3>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-12 text-center">
          <img
            class="w-75 w-lg-100 mt-n7 mt-lg-n8 d-none d-md-block"
            src="https://static.wixstatic.com/media/abb013_9bd7306817b7445daef770ccd91a2770~mv2.png/v1/fill/w_360,h_117,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/abb013_9bd7306817b7445daef770ccd91a2770~mv2.png"
            alt="car image"
          />
          <div class="d-flex align-items-center">
            <h4 class="text-body opacity-7 ms-0 ms-md-auto">Posession</h4>
            <h2 class="text-body ms-2 me-auto">
              47
              <small class="text-sm align-top">%</small>
            </h2>
          </div>
        </div>
        <div class="col-lg-3 col-12 my-auto">
          <h4 class="text-body opacity-9">Team details</h4>
          <hr class="horizontal light mt-1 mb-3" />
          <div class="d-flex align-items-center">
            <div>
              <h6 class="mb-0 text-body">Miclan, DW</h6>
              <h6 class="mb-0 text-body">4th Division</h6>
            </div>
            <div class="ms-lg-6 ms-4">
              <button
                class="btn btn-icon-only btn-rounded btn-outline-dark mb-0 py-0"
              >
                <i class="ni ni-map-big" aria-hidden="true"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardDetail",
};
</script>
